var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Iconify from '@raas/shared/src/components/minimals/iconify';
import { PATH_DASHBOARD } from '@raas/shared/src/routes/paths';
import { ICON_NAME } from '@raas/shared/src/utils/icons';
var icon = function (name) { return _jsx(Iconify, { icon: name }); };
var ICONS = {
    blog: icon(ICON_NAME.blog),
    downloads: icon(ICON_NAME.downloads),
    gear: icon(ICON_NAME.gear),
    eye: icon(ICON_NAME.eye),
    tags: icon(ICON_NAME.tags),
    alerts: icon(ICON_NAME.alerts),
    reports: icon(ICON_NAME.reports),
    import: icon(ICON_NAME.import),
    tag: icon(ICON_NAME.tag),
    export: icon(ICON_NAME.export),
    requirement: icon(ICON_NAME.requirement),
    input: icon(ICON_NAME.input),
    legal: icon(ICON_NAME.legal),
    delta: icon(ICON_NAME.delta),
    history: icon(ICON_NAME.history),
    integration: icon(ICON_NAME.integration),
    legalRequirement: icon(ICON_NAME.legalRequirement),
    translation: icon(ICON_NAME.translation),
};
var navConfig = [
    {
        subheader: ' ',
        items: [
            {
                title: 'View',
                path: PATH_DASHBOARD.overview.root,
                icon: ICONS.legal,
                versionDependent: false,
            },
            {
                title: 'legalRequirements.header',
                path: PATH_DASHBOARD.legalRequirement.root,
                icon: ICONS.legalRequirement,
                permissionRequired: 'LEGAL_REQUIREMENTS',
                versionDependent: true,
            },
            {
                title: 'legalProvisions.translationPage.header',
                path: PATH_DASHBOARD.translation,
                icon: ICONS.translation,
                versionDependent: true,
                translationDependent: true,
            },
        ],
    },
    {
        subheader: 'UPDATES',
        items: [
            {
                title: 'History',
                path: '#disabled',
                icon: ICONS.history,
                disabled: true,
                versionDependent: false,
            },
            {
                title: 'Delta',
                path: PATH_DASHBOARD.delta,
                icon: ICONS.delta,
                versionDependent: true,
            },
            {
                title: 'Alerts',
                path: PATH_DASHBOARD.alerts.list,
                icon: ICONS.alerts,
                versionDependent: false,
            },
        ],
    },
    {
        subheader: 'DATA MANAGEMENT',
        items: [
            { title: 'tagGroups.header', path: PATH_DASHBOARD.tagGroup.root, icon: ICONS.tags, versionDependent: false },
            { title: 'tags.header', path: PATH_DASHBOARD.tag.root, icon: ICONS.tag, versionDependent: false },
            {
                title: 'Internal Data',
                path: '#disabled',
                icon: ICONS.gear,
                disabled: true,
                versionDependent: false,
            },
        ],
    },
    {
        subheader: 'EXPORT',
        items: [
            {
                title: 'Exports',
                path: PATH_DASHBOARD.exports.list,
                icon: ICONS.export,
                versionDependent: false,
            },
            {
                title: 'Integrations',
                path: '#disabled',
                icon: ICONS.integration,
                disabled: true,
                versionDependent: false,
            },
        ],
    },
];
function getNavConfig(_a) {
    var hasVersion = _a.hasVersion, hasTranslations = _a.hasTranslations;
    if (hasVersion && hasTranslations) {
        return navConfig;
    }
    if (hasVersion && !hasTranslations) {
        return navConfig.map(function (group) {
            var items = group.items.filter(function (i) { return !(i === null || i === void 0 ? void 0 : i.translationDependent); });
            return __assign(__assign({}, group), { items: items });
        });
    }
    return navConfig.map(function (group) {
        var items = group.items.filter(function (i) { return !i.versionDependent && !(i === null || i === void 0 ? void 0 : i.translationDependent); });
        return __assign(__assign({}, group), { items: items });
    });
}
export default getNavConfig;
