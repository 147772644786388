import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Collapse, Drawer, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Logo from '@raas/shared/src/components/minimals/logo';
import { NavSectionVertical } from '@raas/shared/src/components/minimals/nav-section';
import Scrollbar from '@raas/shared/src/components/minimals/scrollbar';
import LegalProvisionContext from '@raas/shared/src/contexts/LegalProvisionContext';
import useResponsive from '@raas/shared/src/hooks/useResponsive';
import { NAV } from '@raas/shared/src/shared-config-global';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NavCardItem } from './NavCardItem';
import NavToggleButton from './NavToggleButton';
import getNavConfig from './config-navigation';
export default function NavVertical(_a) {
    var openNav = _a.openNav, onCloseNav = _a.onCloseNav;
    var pathname = useLocation().pathname;
    var _b = useContext(LegalProvisionContext), legalProvisionContext = _b.legalProvisionContext, translations = _b.translations;
    var hasTranslations = (translations === null || translations === void 0 ? void 0 : translations.length) > 1;
    var isDesktop = useResponsive('up', 'lg');
    var theme = useTheme();
    useEffect(function () {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    var renderContent = (_jsxs(Scrollbar, { sx: {
            height: 1,
            '& .simplebar-content': {
                height: 1,
                display: 'flex',
                flexDirection: 'column',
            },
        }, children: [_jsxs(Stack, { spacing: 3, sx: {
                    pt: 3,
                    pb: 2,
                    px: 2.5,
                    flexShrink: 0,
                    alignItems: 'center',
                }, children: [_jsx(Logo, {}), _jsx(Collapse, { in: !!legalProvisionContext, children: _jsx(NavCardItem, { current: legalProvisionContext, theme: theme }) })] }), _jsx(NavSectionVertical, { data: getNavConfig({ hasVersion: !!legalProvisionContext, hasTranslations: hasTranslations }) }), _jsx(Box, { sx: { flexGrow: 1 } })] }));
    return (_jsxs(Box, { component: "nav", sx: {
            flexShrink: { lg: 0 },
            width: { lg: NAV.W_DASHBOARD },
        }, children: [_jsx(NavToggleButton, {}), isDesktop ? (_jsx(Drawer, { open: true, variant: "permanent", PaperProps: {
                    sx: {
                        zIndex: 0,
                        width: NAV.W_DASHBOARD,
                        bgcolor: 'transparent',
                        borderRightStyle: 'dashed',
                    },
                }, children: renderContent })) : (_jsx(Drawer, { open: openNav, onClose: onCloseNav, ModalProps: {
                    keepMounted: true,
                }, PaperProps: {
                    sx: {
                        width: NAV.W_DASHBOARD,
                    },
                }, children: renderContent }))] }));
}
