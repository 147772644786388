var en = {
    common: {
        create: "Create",
        requestRegulation: 'Request Regulation',
        save: "Save",
        add: "Add",
        edit: "Edit",
        open: "Open",
        moreDetails: "More Details",
        editNewTab: "Edit in a new tab",
        openNewTab: "Open in a new tab",
        delete: "Delete",
        deleting: "Deleting",
        details: "Details",
        download: 'Download',
        downloadStart: 'Download will start any second',
        downloadError: 'Error trying to download file',
        cancel: "Cancel",
        createSuccess: "Create success!",
        requestSuccess: 'Request success!',
        updateSuccess: "Update success!",
        deleteSuccess: "Delete success!",
        fieldRequired: "This field is required",
        goBack: "Go Back",
        upload: "Upload",
        home: "Home",
        confirm_delete_message: 'Are you sure you want to delete?',
        positiveIntegerErrorMsg: 'This field should be a positive integer number',
        invalidDate: 'Invalid Date',
        invalidCredentials: 'Invalid email address or password',
        missingPermissions: "You don't have the required permissions to access this site",
        signIn: 'Sign in',
        preview: 'Preview',
        newexport: 'New Export',
        newalert: 'New Alert',
        none: 'None',
        requestError: 'An error occurred while processing request. Please try again later.',
        login: 'Login',
        search: 'Search...',
        searchBy: 'Search by',
        sortBy: 'Sort by',
        selectRelease: 'Select Release',
        referenceTo: 'reference to',
        dependencyTo: 'dependency to',
        createDraft: 'Create Draft',
        createNewVersion: 'Create Version',
        createDraftSuccess: 'Draft created successfully!',
        draftAlreadyExists: 'Draft already exists',
        errorNotDraft: 'Only drafts can be modified',
        newVersionAlreadyExists: 'New unreleased version already exists',
        tags: 'Tags',
        publish: 'Publish',
        azureLoginButton: 'Login with Azure',
        keycloakLoginButton: 'Login with Keycloak',
        or: 'or',
        legalNotice: 'Legal Notice',
        impressum: 'Impressum',
        copyright: 'Copyright',
        kontrolTitle: 'Kontrol.tech',
    },
    legalProvisionAssignments: {
        manageAccess: 'Manage Access',
        assignmentPageTitle: 'Manage Legal Provision Access',
        userGroup: 'User Group',
    },
    diffViewer: {
        toggleDiff: 'Toggle diff',
        toggleViewCurrent: 'Change view. Current:',
        changeGutter: 'Change gutter',
        newVersion: 'New version',
        oldVersion: 'Old version',
    },
    delta: {
        title: 'Delta',
        showUpdatedParagraphs: 'Show updated paragraphs',
        updateDeltaView: 'Compare',
    },
    landingPage: {
        title: 'Legislation Overview',
        newLegislation: 'New Legislation',
        requestLegislation: 'Request Regulation',
        legislationItems: {
            version: 'Version:',
            versionId: 'Version ID:',
            validity: 'Validity:',
            validityFromTo: '{{from}} to {{to}}',
            issueDate: 'Date of Issue:',
            source: 'Source:',
            sourceName: 'Source Name:',
            tags: 'Tags:',
            publisher: 'Publisher:',
            versionTimestamp: 'Version Timestamp:',
            releaseTimestamp: 'Release Timestamp:',
            type: 'Type:',
        },
    },
    navConfig: {
        dashboard: 'DASHBOARD',
        legalProvision: 'LEGAL PROVISION',
        details: 'Details',
    },
    userAccount: {
        title: 'User Account',
        notifications: {
            changePasswordSuccess: 'Password changed successfully',
            changePasswordError: 'Error: changing password failed',
        },
        changePass: {
            validation: {
                requiredNew: 'New password is required',
                requiredOld: 'Old password is required',
                passLength: 'Password must be at least 6 characters long',
                mustMatch: 'Passwords must match',
                beDifferent: 'New password must be different from old password',
            },
            labels: {
                oldPassword: 'Old password',
                newPassword: 'New password',
                confirmPassword: 'Confirm password',
            },
        },
    },
    legalProvisions: {
        country: "Country",
        countryFlag: 'Country Flag',
        header: "Legal Provisions",
        createPageTitle: "Create Legal Provision",
        requestPageTitle: 'Request Regulation',
        editPageTitle: "Edit Legal Provision",
        newVersionPageTitle: "Create Legal Provision Version",
        detailsPageTitle: "Legal Provision Details",
        fullName: "Full Name",
        legalProvision: "Legal Provision",
        abbreviation: "Abbreviation",
        version: "Version",
        validFrom: "Valid From",
        validTo: "Valid To",
        dateOfPublication: "Date Of Publication",
        dateOfAdoption: "Date Of Adoption",
        publisher: "Publisher",
        type: "Type",
        sourceName: "Source Name",
        sourceLink: "Source Link",
        isComplete: "Is Complete",
        errorAbbreviationAlreadyTaken: 'Legal Provision with provided abbreviation already exists.',
        versionName: 'Version',
        versionId: 'Version ID',
        versionTimestamp: 'Version Date',
        releaseId: 'Release ID',
        releaseName: 'Release',
        releaseTimestamp: 'Release Date',
        releaseNotes: 'Release Notes',
        other: 'Other',
        notes: 'Notes:',
        lang: 'Language',
        translationType: 'Translation',
        addTranslation: 'Add Translation',
        dialog: {
            title: 'Confirm Change Field',
            warningMsg: 'The following fields were changed, are you sure you want to save the changes?',
        },
        translationExists: 'Translation already exists',
        translationRequested: 'Translation is requested',
        detailsBox: {
            title: 'Details',
            version: 'Official Version:',
            versionId: 'Version ID:',
            validity: 'Validity:',
            validityFromTo: '{{from}} to {{to}}',
            issueDate: 'Date of Publication:',
            adoptionData: 'Date of Adoption:',
            source: 'Source:',
            sourceName: 'Source Name:',
            tags: 'Tags:',
            publisher: 'Publisher:',
            releaseNotes: 'Kontrol Release Notes:',
            notes: 'Notes:',
            disclaimer: {
                title: 'Disclaimer:',
                main: 'Please note that the translations of legal, technical and regulatory texts available on this website are generated through artificial intelligence. While we strive to ensure the accuracy and reliability of these translations, they may contain errors and should not be considered equivalent to professionally certified translations. We recommend consulting the texts in original language or seeking professional advice when accuracy is critical. Your use of these translations is at your own risk, and we do not accept liability for any inaccuracies or misunderstandings that may arise from their use.',
                bottom: 'Thank you for your understanding.',
            },
        },
        deltaPage: {
            newBoxTitle: 'New version details',
            oldBoxTitle: 'Old version details',
            noPreviousVersion: 'No previous version to compare',
        },
        tableOfContents: {
            title: 'Table of Contents',
            expandAll: 'Expand All',
            collapseAll: 'Collapse All',
        },
        translationPage: {
            header: 'Translation',
            selectTranslation: 'Select Language',
            originalTitle: 'Original language details',
            translationTitle: 'Translation language details',
        },
    },
    documentElements: {
        header: "Document Element",
        createPageTitle: "Create Document Element",
        editPageTitle: "Edit Document Element",
        viewPageTitle: "View Document Element",
        officialParagraphNumber: "Official Paragraph Number",
        title: "Title",
        order: "Order",
        mainBody: "Main Body",
        validFrom: "Valid From",
        validTo: "Valid To",
        child: "Child of",
        addChild: "Add Child Element",
        sibling: "Sibling of",
        addSibling: "Add Sibling Element",
        backToGeneralSearch: "Back to search",
        tooltipEdit: "Edit element",
        moveTo: "Move To",
        moveInto: "Move Into",
        moveIntoIssue: "Some or all elements not moved, please try again",
        selectNewParent: "Select a new parent element",
        selectElements: 'Select elements',
        allCategories: "All Categories",
    },
    files: {
        title: "Files",
        filesUploaded: 'Files uploaded succesfully',
        fileDeleted: 'File deleted succesfully',
        files: 'Files',
        fileDownloadError: 'File download error',
    },
    legalReferences: {
        headerFrom: "References",
        headerTo: "Referenced by",
        dialogHeader: "Edit Reference",
        type: "Type",
        reasoning: "Reasoning",
        paragraph: "Paragraph",
        title: "Title",
        mainBody: "Main Body",
        sourceOfficialParagraphNumber: "Paragraph Number",
        sourceTitle: "Title",
        sourceMainBody: "Source Main Body",
        targetOfficialParagraphNumber: "Paragraph Number",
        targetTitle: "Title",
        targetMainBody: "Target Main Body",
        legalProvision: "Legal Provision",
        targetDocument: 'Document Element',
        openDocument: 'Open',
    },
    sentenceFragments: {
        header: "Sentence Fragments",
        add: "Add Sentence Fragment",
        child: "child",
        edit: "Edit Sentence Fragment",
        text: "Text",
        notes: "Notes",
        usageType: "Usage Type",
        decompositionType: "Decomposition Type",
        questionType: "Question Type",
        sentenceTypeRequired: "Usage Type or Decomposition Type required",
        usageTypeEnum: {
            logic: "Logic",
            property: "Property",
            object: "Object",
        },
        decompositionTypeEnum: {
            condition: "Condition",
            subject: "Subject",
            action: "Action",
        },
        questionTypeEnum: {
            whoIsAffected: 'Who is affected?',
            whatIsPrescribedProhibited: 'What is prescribed/prohibited?',
            location: 'Location',
            time: 'Time',
            vehicle: 'Vehicle',
            environment: 'Environment',
            belongsToWhichSign: 'Belongs to which sign?',
        },
    },
    legalRequirements: {
        header: "Legal Requirements",
        createPageTitle: "Create Legal Requirement",
        editPageTitle: "Legal Requirement",
        name: "Name",
        mainBody: "Main Body",
        reason: "Reason",
        type: "Type",
        reasonForType: "Reason for Type",
        notes: "Notes",
        errorNameAlreadyTaken: 'Legal Requirement with provided name already exists.',
        split: 'Split Legal Requirement',
        details: 'Requirement Details',
    },
    legalRequirementSources: {
        header: "Sources",
        dialogHeader: "Edit Legal Requirement Source",
        isMain: "Is Main",
        usedText: "Used Text",
        reasoning: "Reasoning",
        legalProvision: "Legal Provision",
        documentElement: "Document Element",
        documentElementParagraph: "Paragraph Number",
        documentElementTitle: "Title",
        mainBody: "Main Body",
        reason: "Reason",
        type: "Type",
        reasonForType: "Reason for Type",
        notes: "Notes",
        errorNameAlreadyTaken: 'Legal Requirement with provided name already exists.',
    },
    properties: {
        header: "Properties",
        createPageTitle: "Create Property",
        editPageTitle: "Edit Property",
        name: "Name",
        questionAsked: "Question Asked",
        description: "Description",
        inputsDescription: "Inputs Description",
        reasoningAndDefinition: "Reasoning & Definition",
        futureImplementation: "Future Implementation",
        mathematicalFormulation: "Mathematical Formulation",
        errorNameAlreadyTaken: 'Property with provided name already exists.',
    },
    legalRadar: {
        header: "Legal Radar",
        boxNews: "Box News",
        warning: 'This text will be displayed in all Customer User Interfaces after Saving.',
        form: {
            maxCharacters: 'Max 500 characters',
        },
    },
    technicalRequirements: {
        header: "Technical Requirements",
        createPageTitle: "Create Technical Requirement",
        editPageTitle: "Edit Technical Requirement",
        startNew: 'Start New Technical Requirement',
        name: 'Name',
        conditions: 'Conditions',
        directives: 'Directives',
        verbalRepresentation: 'Verbal Representation',
        logicalRepresentation: 'Logical Representation',
        ruleName: 'Rule Name',
        comment: 'Comment',
        formula: 'Formula',
        errorNameAlreadyTaken: 'Technical Requirement with provided name already exists.',
        overlappedGroups: 'Names of groups are overlapping',
    },
    technicalRequirementSources: {
        header: "Source Legal Requirements",
        dialogHeader: "Edit Technical Requirement Source",
        isMain: "Is Main",
        reasoning: "Reasoning",
        legalRequirement: "Legal Requirement",
        legalRequirementName: "Name",
        mainBody: "Main Body",
    },
    dependencies: {
        deDependenciesHeader: "Document Element Dependencies",
        lrDependenciesHeader: "Legal Requirement Dependencies",
        documentElementParagraphNumber: "Official Paragraph Number",
        documentElementTitle: "Title",
        legalRequirementName: "Name",
        dialogHeader: "Edit Dependency",
        openDocument: 'Open',
        reasoning: "Reasoning",
        type: "Type",
        legalProvision: "Legal Provision",
        targetDocument: 'Target Document Element',
        targetLegalRequirement: 'Target Legal Requirement',
        mainBody: 'Main Body',
    },
    tagGroups: {
        header: "Tag Group Configuration",
        createPageTitle: "Create Tag Group",
        editPageTitle: "Edit Tag Group",
        name: "Name",
        isPublic: "Is Public",
        errorNameAlreadyTaken: 'Tag Group with provided name already exists.',
    },
    tags: {
        header: "Tag Configuration",
        createPageTitle: "Create Tag",
        editPageTitle: "Edit Tag",
        name: "Name",
        description: "Description",
        tagGroup: "Tag Group",
        errorNameAlreadyTaken: 'Tag with provided name already exists in provided tag group.',
    },
    sentenceFragmentsGroup: {
        header: "Sentence Fragments Groups",
        add: "Add Sentence Fragments Group",
        edit: "Edit Sentence Fragments Group",
        name: "Name",
        type: "Type",
        isPropertyAttached: "Is Property Attached",
        typeEnum: {
            directive: "Directive",
            condition: "Condition",
        },
        legalRequirementName: "Legal Requirement",
        usedSentenceFragments: "Used sentence fragments",
        propertyName: "Property",
        exactlyOnePropertyError: "Group must contain exactly one sentence fragment with Usage Type - Property",
        createSentenceFragmentGroup: "Create Group",
        errorNameAlreadyTaken: 'Sentence Fragment with provided name already exists for this Technical Requirement.',
        errorPropertyAlreadyTaken: 'Given property is already used in this Technical Requirement.',
    },
    releases: {
        createPageTitle: "Create New Release",
        changeLogExternal: "External change log",
        changeLogInternal: "Internal change log",
    },
    inputs: {
        header: 'Inputs',
        createPageTitle: 'Create Input',
        editPageTitle: 'Edit Input',
        name: 'Name',
        inputType: 'Input Type',
        dataType: 'Data Type',
        description: 'Description',
        errorNameAlreadyTaken: 'Input with provided name already exists.',
        errorInUse: "Input is used and can't be deleted",
        assignInputs: 'Assign Inputs',
        inputTypeValues: {
            interface: 'Interface',
            parameter: 'Parameter',
            file: 'File',
        },
        dataTypeValues: {
            boolean: 'Boolean',
            number: 'Number',
            object: 'Object',
            other: 'Other',
        },
    },
    versions: {
        versionName: 'Version',
        versionDate: 'Version Date',
        releaseDate: 'Release Date',
    },
    demo: {
        title: "English",
        introduction: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
    docs: {
        hi: "Hi",
        description: "Need help? \n Please check our docs.",
        documentation: "documentation",
    },
    exports: {
        description: 'Description',
        download: 'Download',
        createPageTitle: 'New Export',
        editPageTitle: 'Edit Export',
        header: 'Exports',
        selected: 'Selected',
        name: 'Export Name',
        createdAt: 'Created At',
    },
    reports: {
        header: 'Reports',
        subHeader: 'SIMULATION',
        name: 'Report Name',
        createdAt: 'Created At',
    },
    import: {
        header: 'Import',
        createPageTitle: 'Import',
        scenarios: 'Choose scenarios to upload',
        assets: 'Choose assets to upload',
    },
    alerts: {
        header: 'Alerts',
        name: 'Alert',
        time: 'Date of Alert',
        createdAt: 'Created At',
        userGroupId: 'User Group',
        createPageTitle: 'New Alert',
        editPageTitle: 'Alert',
        selected: 'Selected',
    },
    app: "app",
    user: "user",
    list: "list",
    edit: "edit",
    shop: "shop",
    blog: "blog",
    post: "post",
    mail: "mail",
    chat: "chat",
    cards: "cards",
    posts: "posts",
    create: "create",
    kanban: "kanban",
    general: "general",
    banking: "banking",
    booking: "booking",
    profile: "profile",
    account: "account",
    product: "product",
    invoice: "invoice",
    details: "details",
    checkout: "checkout",
    calendar: "calendar",
    analytics: "analytics",
    ecommerce: "e-commerce",
    management: "management",
    menu_level: "menu level",
    menu_level_2a: "menu level 2a",
    menu_level_2b: "menu level 2b",
    menu_level_3a: "menu level 3a",
    menu_level_3b: "menu level 3b",
    menu_level_4a: "menu level 4a",
    menu_level_4b: "menu level 4b",
    item_disabled: "item disabled",
    item_label: "item label",
    item_caption: "item caption",
    item_external_link: "item external link",
    description: "description",
    other_cases: "other cases",
    item_by_roles: "item by roles",
    only_admin_can_see_this_item: "Only admin can see this item",
};
export default en;
