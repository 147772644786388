var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { parseISO } from 'date-fns';
import axios from '@raas/shared/src/utils/axios';
var LegalProvisionsService = /** @class */ (function () {
    function LegalProvisionsService() {
    }
    LegalProvisionsService.prototype.getAll = function (abortSignal) {
        return __awaiter(this, void 0, Promise, function () {
            var config, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {};
                        if (abortSignal) {
                            config.signal = abortSignal;
                        }
                        return [4 /*yield*/, axios.get('/legal-provisions', config)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.map(mapToLegalProvision)];
                }
            });
        });
    };
    LegalProvisionsService.prototype.getAllAssigned = function (abortSignal) {
        return __awaiter(this, void 0, Promise, function () {
            var config, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {};
                        if (abortSignal) {
                            config.signal = abortSignal;
                        }
                        return [4 /*yield*/, axios.get('/legal-provisions/assigned', config)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.map(mapToLegalProvision)];
                }
            });
        });
    };
    LegalProvisionsService.prototype.get = function (id, abortSignal) {
        return __awaiter(this, void 0, Promise, function () {
            var config, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {};
                        if (abortSignal) {
                            config.signal = abortSignal;
                        }
                        return [4 /*yield*/, axios.get("/legal-provisions/".concat(id), config)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, mapToLegalProvision(result.data)];
                }
            });
        });
    };
    LegalProvisionsService.prototype.getAllVersion = function (entityId) {
        return __awaiter(this, void 0, Promise, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("/legal-provisions/".concat(entityId, "/versions"))];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.map(mapToLegalProvision)];
                }
            });
        });
    };
    LegalProvisionsService.prototype.getDetails = function (id, headers, abortSignal) {
        return __awaiter(this, void 0, Promise, function () {
            var config, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        config = {};
                        if (abortSignal) {
                            config.signal = abortSignal;
                        }
                        if (headers) {
                            config.headers = __assign(__assign({}, axios.defaults.headers.common), headers);
                        }
                        return [4 /*yield*/, axios.get("/legal-provisions/".concat(id, "/tree"), config)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, mapToLegalProvisionDetails(result.data)];
                }
            });
        });
    };
    LegalProvisionsService.prototype.delete = function (id) {
        return axios.delete("/legal-provisions/".concat(id));
    };
    LegalProvisionsService.prototype.create = function (postData) {
        return __awaiter(this, void 0, Promise, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.post("/legal-provisions", mapToRequestBody(postData))];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                }
            });
        });
    };
    LegalProvisionsService.prototype.createNewVersion = function (id, versionName) {
        return __awaiter(this, void 0, Promise, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.post("/legal-provisions/".concat(id, "/new-version"), {
                            version: versionName,
                        })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                }
            });
        });
    };
    LegalProvisionsService.prototype.createDraft = function (id, versionId) {
        return __awaiter(this, void 0, Promise, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.post("/legal-provisions/".concat(id, "/draft"))];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data];
                }
            });
        });
    };
    LegalProvisionsService.prototype.edit = function (postData, id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, axios.put("/legal-provisions/".concat(id), mapToRequestBody(postData))];
            });
        });
    };
    return LegalProvisionsService;
}());
var mapToLegalProvision = function (legalProvision) { return ({
    id: legalProvision.id,
    entityId: legalProvision.entityId,
    fullName: legalProvision.fullName,
    abbreviation: legalProvision.abbreviation,
    originalVersion: legalProvision.originalVersion,
    validFrom: legalProvision.validFrom,
    validTo: legalProvision.validTo,
    dateOfPublication: parseISO(legalProvision.dateOfPublication),
    dateOfAdoption: legalProvision.dateOfAdoption ? parseISO(legalProvision.dateOfAdoption) : null,
    publisher: legalProvision.publisher,
    type: legalProvision.type,
    sourceName: legalProvision.sourceName,
    sourceLink: legalProvision.sourceLink,
    tags: legalProvision.tags,
    versionId: legalProvision.versionId,
    versionName: legalProvision.versionName,
    versionTimestamp: parseISO(legalProvision.versionTimestamp),
    releaseId: legalProvision.releaseId,
    releaseTimestamp: legalProvision.releaseTimestamp
        ? parseISO(legalProvision.releaseTimestamp)
        : null,
    isComplete: legalProvision.isComplete,
    countryFlag: legalProvision.countryFlag,
    changeLog: legalProvision.changeLog,
    notes: legalProvision.notes
}); };
var mapToLegalProvisionDetails = function (legalProvision) { return (__assign(__assign({}, mapToLegalProvision(legalProvision)), { rootElement: legalProvision.rootElement })); };
var mapToRequestBody = function (legalProvision) { return ({
    id: legalProvision.id,
    fullName: legalProvision.fullName,
    abbreviation: legalProvision.abbreviation,
    originalVersion: legalProvision.originalVersion,
    validFrom: legalProvision.validFrom,
    validTo: legalProvision.validTo,
    dateOfPublication: legalProvision.dateOfPublication,
    dateOfAdoption: legalProvision.dateOfAdoption,
    publisher: legalProvision.publisher,
    type: legalProvision.type,
    sourceName: legalProvision.sourceName,
    sourceLink: legalProvision.sourceLink,
    tagIds: legalProvision.tagIds,
    versionName: legalProvision.versionName,
    releaseId: legalProvision.releaseId,
    isComplete: legalProvision.isComplete,
    countryFlag: legalProvision.countryFlag,
    notes: legalProvision.notes,
}); };
var legalProvisionsService = new LegalProvisionsService();
export default legalProvisionsService;
