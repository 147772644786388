var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '@raas/shared/src/utils/axios';
import legalProvisionsService from '@raas/shared/src/service/api/LegalProvisionsService';
import LegalProvisionContext from './LegalProvisionContext';
import { LEGAL_PROVISION_STATE } from './utils';
var key = 'legalProvisionContext';
var EntityIdHeader = 'Entity-Id';
var VersionIdHeader = 'Version-Id';
var VersionTimestampHeader = 'Version-Timestamp';
var ReleaseTimestampHeader = 'Release-Timestamp';
export function LegalProvisionContextProvider(_a) {
    var _this = this;
    var children = _a.children, requiredPermission = _a.requiredPermission;
    var location = useLocation();
    var _b = useState(null), state = _b[0], setState = _b[1];
    var _c = useState(null), filteredRoot = _c[0], setFilteredRoot = _c[1];
    var _d = useState(false), activeSearchMode = _d[0], setActiveSearchMode = _d[1];
    var _e = useState(null), oldLegalProvision = _e[0], setOldLegalProvision = _e[1];
    var _f = useState(null), currentLegalProvision = _f[0], setCurrentLegalProvision = _f[1];
    // legal provision translations
    var _g = useState(null), translations = _g[0], setTranslations = _g[1];
    var _h = useState(null), currentTranslationId = _h[0], setCurrentTranslationId = _h[1];
    // document elements translations
    var _j = useState(null), documentsTranslations = _j[0], setDocumentsTranslations = _j[1];
    var _k = useState(null), originalLanguageId = _k[0], setOriginalLanguageId = _k[1];
    var _l = useState(null), selectedLanguageId = _l[0], setSelectedLanguageId = _l[1];
    var legalProvisionTranslationView = useMemo(function () { return ({
        originalLanguageId: originalLanguageId,
        setOriginalLanguageId: setOriginalLanguageId,
        selectedLanguageId: selectedLanguageId,
        setSelectedLanguageId: setSelectedLanguageId
    }); }, [originalLanguageId, selectedLanguageId]);
    var setLegalProvisionContext = useCallback(function (legalProvision) {
        var _a, _b;
        if (!legalProvision) {
            return;
        }
        localStorage.setItem(key, JSON.stringify(legalProvision));
        axios.defaults.headers.common[EntityIdHeader] = legalProvision.entityId;
        axios.defaults.headers.common[VersionIdHeader] = legalProvision.versionId;
        axios.defaults.headers.common[VersionTimestampHeader] =
            (_a = legalProvision.versionTimestamp) === null || _a === void 0 ? void 0 : _a.toISOString();
        axios.defaults.headers.common[ReleaseTimestampHeader] =
            (_b = legalProvision.releaseTimestamp) === null || _b === void 0 ? void 0 : _b.toISOString();
        setState(legalProvision);
    }, []);
    var clearLegalProvisionContext = useCallback(function () {
        localStorage.removeItem(key);
        delete axios.defaults.headers.common[EntityIdHeader];
        delete axios.defaults.headers.common[VersionIdHeader];
        delete axios.defaults.headers.common[VersionTimestampHeader];
        delete axios.defaults.headers.common[ReleaseTimestampHeader];
        setState(null);
    }, []);
    var entityIdHeaderValue = String(axios.defaults.headers.common[EntityIdHeader]);
    // legal state
    var _m = useState({ type: LEGAL_PROVISION_STATE.DEFAULT, elementId: '' }), legalProvisionState = _m[0], setLegalProvisionState = _m[1];
    var value = useMemo(function () { return ({
        legalProvisionContext: state,
        setLegalProvisionContext: setLegalProvisionContext,
        clearLegalProvisionContext: clearLegalProvisionContext,
        entityIdHeaderValue: entityIdHeaderValue,
        requiredPermission: requiredPermission,
        oldLegalProvision: oldLegalProvision,
        setOldLegalProvision: setOldLegalProvision,
        currentLegalProvision: currentLegalProvision,
        setCurrentLegalProvision: setCurrentLegalProvision,
        filteredRoot: filteredRoot,
        setFilteredRoot: setFilteredRoot,
        activeSearchMode: activeSearchMode,
        setActiveSearchMode: setActiveSearchMode,
        legalProvisionState: legalProvisionState,
        setLegalProvisionState: setLegalProvisionState,
        translations: translations,
        setTranslations: setTranslations,
        currentTranslationId: currentTranslationId,
        setCurrentTranslationId: setCurrentTranslationId,
        documentsTranslations: documentsTranslations,
        setDocumentsTranslations: setDocumentsTranslations,
        legalProvisionTranslationView: legalProvisionTranslationView,
    }); }, [
        state,
        setLegalProvisionContext,
        clearLegalProvisionContext,
        entityIdHeaderValue,
        requiredPermission,
        oldLegalProvision,
        currentLegalProvision,
        filteredRoot,
        activeSearchMode,
        legalProvisionState,
        translations,
        currentTranslationId,
        documentsTranslations,
        legalProvisionTranslationView
    ]);
    useEffect(function () {
        var dateFormat = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
        var reviver = function (_, valueToParse) {
            return typeof valueToParse === 'string' && dateFormat.test(valueToParse)
                ? new Date(valueToParse)
                : valueToParse;
        };
        var setContextFromLocalStorage = function () {
            var storedValue = localStorage.getItem(key);
            if (storedValue) {
                var storedState = JSON.parse(storedValue, reviver);
                if (storedState.versionId) {
                    setLegalProvisionContext(storedState);
                }
            }
        };
        var setContextFromURL = function (queryParams, entityId, versionId) { return __awaiter(_this, void 0, void 0, function () {
            var versionTimestamp, releaseTimestamp, legalProvisionsFromUrl, found;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        versionTimestamp = queryParams.get(VersionTimestampHeader);
                        releaseTimestamp = queryParams.get(ReleaseTimestampHeader);
                        axios.defaults.headers.common[EntityIdHeader] = entityId;
                        axios.defaults.headers.common[VersionIdHeader] = versionId;
                        axios.defaults.headers.common[VersionTimestampHeader] = versionTimestamp;
                        if (releaseTimestamp) {
                            axios.defaults.headers.common[ReleaseTimestampHeader] = releaseTimestamp;
                        }
                        return [4 /*yield*/, legalProvisionsService.getAllAssigned()];
                    case 1:
                        legalProvisionsFromUrl = _a.sent();
                        found = false;
                        legalProvisionsFromUrl.forEach(function (legalProvision) {
                            var _a, _b;
                            if (legalProvision.entityId === entityId &&
                                legalProvision.versionId === versionId &&
                                ((versionTimestamp &&
                                    ((_a = legalProvision.versionTimestamp) === null || _a === void 0 ? void 0 : _a.toISOString()) === versionTimestamp) ||
                                    !versionTimestamp) &&
                                ((releaseTimestamp &&
                                    ((_b = legalProvision.releaseTimestamp) === null || _b === void 0 ? void 0 : _b.toISOString()) === releaseTimestamp) ||
                                    !releaseTimestamp)) {
                                found = true;
                                setLegalProvisionContext(legalProvision);
                            }
                        });
                        if (!found) {
                            clearLegalProvisionContext();
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        var setContext = function () {
            var queryParams = new URLSearchParams(window.location.search);
            var entityId = queryParams.get(EntityIdHeader);
            var versionId = queryParams.get(VersionIdHeader);
            if (!state && entityId && versionId) {
                setContextFromURL(queryParams, entityId, versionId);
            }
            else {
                setContextFromLocalStorage();
            }
        };
        setContext();
    }, [setLegalProvisionContext, clearLegalProvisionContext, location.search, state]);
    useEffect(function () {
        setLegalProvisionContext(state);
    }, [location, setLegalProvisionContext, state]);
    return _jsx(LegalProvisionContext.Provider, { value: value, children: children });
}
