import { createContext, useContext } from 'react';
import { LEGAL_PROVISION_STATE } from './utils';
var LegalProvisionContext = createContext({
    legalProvisionContext: null,
    setLegalProvisionContext: function (value) { },
    clearLegalProvisionContext: function () { },
    entityIdHeaderValue: '',
    requiredPermission: 'INTERNAL_ACCESS',
    oldLegalProvision: null,
    setOldLegalProvision: function () { },
    currentLegalProvision: null,
    setCurrentLegalProvision: function () { },
    filteredRoot: null,
    setFilteredRoot: function () { return ''; },
    activeSearchMode: false,
    setActiveSearchMode: function () { return false; },
    legalProvisionState: { type: LEGAL_PROVISION_STATE.DEFAULT, elementId: '' },
    setLegalProvisionState: function () { },
    translations: null,
    setTranslations: function () { },
    currentTranslationId: null,
    setCurrentTranslationId: function () { },
    documentsTranslations: null,
    setDocumentsTranslations: function () { },
    legalProvisionTranslationView: {
        originalLanguageId: null,
        setOriginalLanguageId: function () { },
        selectedLanguageId: null,
        setSelectedLanguageId: function () { },
    },
});
export var useLegalProvisionContext = function () {
    var context = useContext(LegalProvisionContext);
    if (!context) {
        throw new Error('useLegalProvisionContext must be used within a LegalProvisionContextProvider');
    }
    return context;
};
export default LegalProvisionContext;
