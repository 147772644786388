var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '@raas/shared/src/components/minimals/loading-screen';
// ----------------------------------------------------------------------
var Loadable = function (Component) { return function (props) {
    return (_jsx(Suspense, { fallback: _jsx(LoadingScreen, {}), children: _jsx(Component, __assign({}, props)) }));
}; };
// ----------------------------------------------------------------------
export var LoginPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/LoginPage'); }));
export var LandingPageOverviewPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/landing-page/LandingPageOverview'); }));
export var LegalProvisionDetailsPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/legal-provision/LegalProvisionDetailsPage'); }));
export var LegalProvisionRequestPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/legal-provision/LegalProvisionRequestPage'); }));
export var LegalProvisionEditPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/legal-provision/LegalProvisionEditPage'); }));
export var DocumentElementCreatePage = Loadable(lazy(function () { return import('@raas/shared/src/pages/document-element/DocumentElementCreatePage'); }));
export var DocumentElementEditPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/document-element/DocumentElementEditPage'); }));
export var TagGroupListPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/tag-group/TagGroupListPage'); }));
export var TagGroupCreatePage = Loadable(lazy(function () { return import('@raas/shared/src/pages/tag-group/TagGroupCreatePage'); }));
export var TagGroupEditPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/tag-group/TagGroupEditPage'); }));
export var TagListPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/tag/TagListPage'); }));
export var TagCreatePage = Loadable(lazy(function () { return import('@raas/shared/src/pages/tag/TagCreatePage'); }));
export var TagEditPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/tag/TagEditPage'); }));
export var DeltaPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/delta/Delta'); }));
export var UserChangePassword = Loadable(lazy(function () { return import('@raas/shared/src/pages/user/Account'); }));
export var Page404 = Loadable(lazy(function () { return import('@raas/shared/src/pages/Page404'); }));
export var ExportsListPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/exports/ExportsListPage'); }));
export var ExportsEditPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/exports/ExportsEditPage'); }));
export var LegalRequirementListPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/legal-requirement/LegalRequirementListPage'); }));
export var LegalRequirementCreatePage = Loadable(lazy(function () { return import('@raas/shared/src/pages/legal-requirement/LegalRequirementCreatePage'); }));
export var LegalRequirementEditPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/legal-requirement/LegalRequirementEditPage'); }));
export var AlertsListPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/alerts/AlertsListPage'); }));
export var AlertsEditPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/alerts/AlertsEditPage'); }));
export var LegalProvisionWithTranslationPage = Loadable(lazy(function () { return import('@raas/shared/src/pages/legal-provision/LegalProvisionWithTranslationPage'); }));
